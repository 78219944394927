<template>
    <div class="container-fluid mt-3 mb-3">
        <CCard>
            <CRow class="p-4">
                <CCol cols="12" :md="12">
                    <div v-if="serverErrors.length">
                        <CAlert
                                v-model="showDismissibleAlert"
                                color="danger"
                                closeButton
                                v-for="err in serverErrors"
                                :key="err"
                        >
                            {{ err }}
                        </CAlert>
                    </div>
                    <validation-observer ref="formValidator">
                        <form v-on:submit.prevent="importMeter">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group" v-if="projectsList">
                                        <label>Projects</label>
                                        <multiselect
                                                v-model="meter.project"
                                                :options="projectsList"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :clear-on-select="false"
                                                :preserve-search="true"
                                                placeholder="Select Project"
                                                label="name"
                                                track-by="id"
                                                :preselect-first="false"
                                        >
                                            <template
                                                    slot="selection"
                                                    slot-scope="{ values, search, isOpen }"
                                            ><span
                                                    class="multiselect__single"
                                                    v-if="values.length &amp;&amp; !isOpen"
                                            >{{ values.length }} options selected</span
                                            ></template
                                            >
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>File </label><br>
                                        <input
                                                required
                                                ref="fileInput"
                                                type="file"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                          <div class="">
                            Download Example file: <a download="" href="/example_meters.xlsx">Example file</a>
                          </div>
                        </form>
                    </validation-observer>
                </CCol>
            </CRow>
        </CCard>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";

export default {
    name: "InventoryMeters",
    components: {
        Multiselect,
    },
    data: () => {
        return {
            meter: {
                project: {},
            },
            serverErrors: [],
            showDismissibleAlert: false,
            isLoading: false,
        };
    },
    methods: {
        importMeter() {
            this.loadingState = true
            const formData = new FormData();
            formData.append('project_id', this.meter.project ? this.meter.project.id : "");
            formData.append('file', this.$refs.fileInput.files[0]);
            const headers = {'Content-Type': 'multipart/form-data'};
            this.$store
                .dispatch("Meter/import", formData, headers)
                .then(() => {
                    this.showToast = true;
                    this.$toastr.s(
                        "success",
                        "Your file uploading. When upload completed, then notify to you.",
                        "Meter Importing"
                    );
                })
                .catch((err) => {
                    let errors = err?.response?.data?.message
                    if (Array.isArray(errors)) {
                        for (let field of Object.keys(errors)) {
                            this.$toastr.e("error", errors[field][0], "Error!");
                        }
                    } else {
                        this.$toastr.e("error", errors, "Error!");
                    }
                }).finally(() => {
                this.loadingState = false
                this.$refs.fileInput.value = ''
            });
        },
    },
    mounted() {
        this.$store.dispatch("Projects/getProjectsList");
    },
    computed: {
        ...mapGetters("Projects", ["projectsList"]),
    },
};
</script>


<style scoped lang="scss">
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
